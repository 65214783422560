
  import { defineComponent } from 'vue';

  // コンポーネントを読み込む
  import Button from './atoms/button.vue';

  export default defineComponent({
    name: 'complete',

    components: {
      Button,
    },

    props: {
      groupURL: String,
      groupTitle: String,
      searchURL: String,
      calURL: String,
      message: String,
      completeTitle: String,
      calendarMessage: String
    },

    data() {
      return {
        calendarMessage: !this.calendarMessage? '登録されたカレンダーはこちら': this.calendarMessage,
        completeDesc: this.message
      }
    },

    methods: {
      viewGroupList() {
        //this.$emit('viewGroupList');
        //this.$router.push('/');
        location.href = '/'
      }
    },
    mounted() {
      this.completeDesc = (this.message && this.message.indexOf('カレンダー') > -1)? this.message: (this.calURL)? 'カレンダーへの反映は後ほど行われます。': '' 
    },
  });
