class saihou {
  constructor(vueObj, $, func, moment) {

    try {
      // Vueインスタンスを取得
      this.vueObj = vueObj

      this.store = vueObj.store

      this.isProd = this.vueObj.isProd

      // jQueryインスタンスを取得
      this.$ = $

      // フォーム関連関数を取得
      this.func = func

      // momentインスタンスを取得
      this.moment = moment

      // propsを取得
      //this.props = this.vueObj.getProps()
      this.thisFormParams = vueObj.thisFormParams

      this.userEmail = this.vueObj.userEmail

      // 投稿先slackチャンネル情報
      this.slackChannel = vueObj.slackChannel
      this.demo = this.isProd ? "" : "Demo"
      this.key = this.store.state.apis[`loginKey${this.demo}`]

      // 案件ID検索とか理由カテゴリ取得するAPI
      this.getDataApiURL = this.store.state.apis.getDataApiURL

      // talknote投稿用RL
      //this.talknotePOSTURL = this.store.state.apis.talknotePOSTURL

      // DBを操作するAPI
      this.dbApiURL = this.store.state.apis[`dbApiURL${this.demo}`]
      
      // slack チャンネル URL
      this.groupURL = this.slackChannel.workSpaceURL + this.slackChannel.id 

       // フォームタイトル
      this.groupTitle = this.thisFormParams[0].replace(/\n/gi,"").replace(/\t/gi,"")

      // talknoteの投稿先アドレス
      // this.talknoteGroupAddress = this.thisFormParams[2].replace(/\n/gi,"").replace(/\t/gi,"")

      // カレンダー未反映通知slackチャンネルID
      this.calendarFailureApplySlackChannelID = this.isProd? this.store.state.apis.calendarFailureApplySlackChannelID: this.store.state.apis.demoForwardChannelID

      // カレンダー未反映通知メールアドレス
      this.talknoteFailureApplyMailAddress = this.store.state.apis.calendarFailureApplyMail

      // 変数定義
      this.abortController = {},
      this.intervalObj = {},
      this.inputApType = '',
      this.inputSubjectId = '',
      this.inputComName = '',
      this.inputMtgDate = '',
      this.inputMtgDay = '',
      this.inputMtgStart = '',
      this.inputMtgEnd = '',
      this.inputRevisitType = '',
      this.inputHook = '',
      this.inputSuggestion = '',
      this.inputMtgType = '',
      this.inputMtgAdd = '',
      this.inputStation = '',
      this.inputFollowNeed = '',
      this.inputFollowerName = '',
      this.inputRemark = '',
      this.inputVisitArea = '',
      this.inputStationMin = "",
      this.formClearFlag = 0, 
      this.startIdName = 'MtgStart',
      this.endIdName = 'MtgEnd'

      // 初期化
      this.init()

    } catch(e) {
      console.error(e.stack, e.message)

      let text = []

      if(!this.vueObj) {
        text.push('Vueインスタンスが取得できませんでした。')
      }

      if(!this.$) {
        text.push('jQueryが取得できませんでした。')
      }

      if(text.length == 0) {
        alert('予期せぬエラーが発生しました。')
      } else {
        alert(text.join('\n'))
      }

      return false
    }

  }

  init() {
    const $ = this.$;

    $('input[name="q"]').css({ 
      'border': 'none',
      'width': '230px',
      'height': '20px',
      'font-size': '12px',
      'line-height': '26px',
      'padding-left': '5px',
      'color': '#444'
    })

    $('.autoG,.gotAP').css('display', 'none')
    
    this.actionInit()
  }

  actionInit() {
    const that = this
    const $ = this.$
    const func = this.func
    const moment = this.moment
    const officeData = new OfficeDatas()

    func.startEndTime(this.startIdName, this.endIdName)

    /****************************************
     * アクション定義
    *****************************************/

    /* 
      ▼案件IDフォーマット
      AP[グループID]_[投稿ミリ秒]
      TW[グループID]_[投稿ミリ秒]
      OT[グループID]_[投稿ミリ秒]
      SL[グループID][T or O]_[投稿ミリ秒]
      └ 投稿者の所属エリアにより、T(Tokyo)orO(Osaka)で決まる
    */

    // 案件ID検索
    $('#SubjectId').off('input.search').on('input.search', function(e) {
      
      const _this = $(this)
      const subject_id = $(this).val()
      let textCount = 22

      if(subject_id.indexOf('_') == 13) {
        textCount = 26
      }

      let before = _this.data('subject_id')

      if(subject_id.indexOf('sungrove') == 0) {
        textCount = 11
      } else {
        $('#wrap2, .autoG, .gotAP, .form_wrap').slideUp()
      }

      if(subject_id.length < textCount || (before && before != subject_id)|| e.originalEvent.inputType.indexOf("delete") == 0) {
        $('#status_comment').text('')
        _this.data('subject_id', '')
        before = ""

        if($('.form_wrap').eq(0).css('display') == 'block') {
          $('.auto').prop('readonly', true);
          that.formClearFlag = 1
          _this.trigger('input.clear')
          _this.val(subject_id)
        }
        return false
      }

      const result = subject_id.match(/(^[A-Z]{2}[0-9A-Z]{11}?_[0-9]{13}$|^[A-Z]{2}[0-9]{6}[A-Z]?_[0-9]{13}$|^sungrove[123])/)
      
      if(!result) {
        if(subject_id.indexOf('sungrove') > -1) {
          _this.val(subject_id)
        } 

        //alert('案件IDを正しく入力してください。')

        //_this.val('')

        return false
      }
      
      // 入力されたら
      if($('#status_comment').text().indexOf('検索中') == -1) {
        $('.events').remove()

        if(subject_id.startsWith('sungrove') == false) {      

          const reg1 = /^[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]|[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]$/

          const reg2 = /[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]/g

          $('#SubjectId').attr('readonly', true)

          if(reg1.test(subject_id)) {
            subject_id = subject_id.replace(reg2, "")
          }

          $('#btn').addClass('disabled').removeClass('pulse')
          $('.auto').val('')
          $('#status_comment').text('検索中...🔎 少々お待ちください😣').css('color', '#757575')
          // Loading表示
          that.vueObj.$emit('viewLoader', '検索中')
          $('#loading_form').css('display', 'block')

          // 案件ID検索APIに投げる
          console.log(new Date())

          // 案件ID検索APIに投げるGAS API URL
          //let searchApiURL = that.getDataApiURL + '?type=searchSubjectId&SubjectId=' + subject_id
          let searchApiURL = that.store.state.apis.searchSubjectsIDApiURL + subject_id
          that.formClearFlag = 1
          _this.trigger('input.clear')
          _this.val(subject_id)

          // Loading表示
          that.vueObj.viewLoader('検索中')

          // 案件ID検索開始
          fetch(searchApiURL)
          .then(response => response.json())
          .then(response => {
            // Loading非表示
            that.vueObj.viewLoader()
            console.log(response)

            const openAction = () => {
              $('.form_wrap').slideDown()           
              $('select').css('display', 'block')
              _this.attr('readonly', false)
              $('#loading_form').css('display', 'none')
              $('#status_comment').text('')
              $('.autoG,#wrap2').slideDown()
            }

            let result_CalID = func.checkCalID(response)

            if(result_CalID !== 99) {
              that.del_flag = 1
              that.result_del = response.splice(result_CalID, 1)
              that.del_cal_id = that.result_del[0].cal_id
              that.del_evnet_id = that.result_del[0].event_id
              that.del_desc = that.result_del[0].description
            }

            if(response.length >= 1) {
              openAction()
              
              $('#status_comment').text('対象の案件を見つけました👀')

              let resTitle = response[0].title
              let exchangeEle = {}
              let fullname = that.store.state.userData.displayName

              that.inputCalName = response[0].cal_name
              that.resCalId = response[0].cal_id
              that.resEventId = response[0].event_id
              that.resMtgAdd = response[0].location

              const regSaihou = /\[.*再.*\]/
              const errorText = func.titlePattern(resTitle, regSaihou)
              if (errorText) {
                $('#status_comment').html(errorText).css({'color': 'red', 'font-size': '16px'})
              }

              const isIncludeSaihouCal = officeData.checkIncludeSaihouCal(that.resCalId)
              const isIncludeSelfSalesCal = officeData.checkIncludeSelfSalesCal(that.resCalId)
              const isRescanCal = officeData.checkRescanCal(that.resCalId)

              if(resTitle.indexOf('///*') !== -1 || resTitle.indexOf('*///') !== -1) {
                exchangeEle = func.v2Title(response[0], moment)
              } else if(isIncludeSaihouCal || (isRescanCal && regSaihou.test(resTitle))) {
                exchangeEle = func.saihou(response[0], moment)
                that.inputSaihou = '(再訪)'
              } else if(resTitle.indexOf('///') !== -1) {
                exchangeEle = func.v1Title(response[0], moment)
              }
              
              if(isIncludeSelfSalesCal) { //自アポ
                $('.filed_proxy').css('display', 'none')
              }
              
              if(fullname.indexOf('(') !== -1 || fullname.indexOf('（') !== -1) {
                fullname = fullname.split(/\(|（/)[0]
              }

              that.inputComName = exchangeEle.com_name

              $('#ComName').val(that.inputComName)

            } else {
              $('.auto').attr('placeholder', '')
              _this.attr('readonly', false)
              $('#status_comment').text('もう一度IDを確認して検索してください。').css('color', '#e91e63')
            }
          })
          .catch((e) => {
            console.error(e.stack, e.message)
          })

          return true;
        } else if(subject_id == 'sungrove123') {
          $('.auto').prop('readonly', false);
          $('select').css('display', 'block')
          $('#wrap2, .autoG, .gotAP, .form_wrap').slideDown()
          $('#status_comment').text('案件IDがない案件の為、手動入力モードに切り替えました📝').css('color', '#e91e63')
        }
      }
    })

    // [案件IDクリア]
    $('#SubjectId').off('input.clear').on('input.clear', function() {
      const subject_id = $('#SubjectId').val()

      if(subject_id == "" || that.formClearFlag == 1) {
        that.formClearFlag = 0
        $('#wrap2, .autoG, .gotAP, .form_wrap').slideUp()

        $('.events').remove()
        $('#status_comment').text('案件IDを入力すると検索開始します').css('color', '#757575')

        func.clearInput($('input[type="text"], input[type="Number"], input[type="url"], #Remarks'))   
        func.checkOut($('.form_check, .required'))
        func.clearSelect($('.form_select'))
        $('.ListFollower').remove()
        $('#feild_FollowerName').hide()
        $('#Remark').val('')
        $('.required, .required + *').removeAttr('style')
      }
    })

    // [再訪種別：項目表示/非表示]
    $(document).on('click','[name="RevisitType"]', function() {
      let RevisitType_ele = func.check($('[name="RevisitType"]'))

      if(RevisitType_ele.indexOf('商談') !== -1){
        $('#feild_hook,#feild_suggestion').slideDown(500)
      }else{
        $('#feild_hook,#feild_suggestion').slideUp(500)
      
        func.checkRemove($('[name="Hook"]'))
        func.checkRemove($('[name="Suggestion"]'))
      }
    })

    // [再訪種別：同時選択不可]
    $('#RevisitType_nego').off('click.RevisitType_neg').on('click.RevisitType_neg', function() {
      if($(this).prop('checked')) {
        $('#RevisitType_mtg,#RevisitType_payment').prop('checked', false)
        $(this).prop('checked', true)
        $('[name="Hook"],[name="Suggestion"]').addClass('required')
      }
    })

    $('#RevisitType_mtg,#RevisitType_payment').off('click.RevisitType_mtg').on('click.RevisitType_mtg', function() {
      if($(this).prop('checked')) {
        $('#RevisitType_nego').prop('checked', false)
        $(this).prop('checked', true)
        $('[name="Hook"], [name="Suggestion"]').removeClass('required')
      }
    })

    // フック
    $('[name="Hook"]').off('click.Hook').on('click.Hook',function() {
      $('#s_cms, #s_ec, #s_seo, #s_itzi, #s_sub').prop('checked', false)
      $('#s_cms, #s_ec, #s_seo, #s_itzi, #s_sub').attr("disabled", false)
      
      if($(this).val() == 'イツザイ') {
        $('#s_cms, #s_ec, #s_seo').attr("disabled", true)
        $('#s_itzi').prop("checked", true)
        $("#s_itzi").prop("disabled", true)
        $('#s_sub').prop("disabled", false)
      } else if ($(this).val() == '助成金Lab') {
        $('#s_cms, #s_ec, #s_seo, #s_itzi').attr("disabled", true)
        $('#s_sub').prop("checked", true)
        $('#s_sub').prop("disabled", true)
      } else {
        $('#s_cms, #s_ec, #s_seo').attr("disabled", false)
        $('#s_itzi, #s_sub').attr("disabled", true)
      }
    })

    // 提案内容(同時選択不可)
    $('#s_ec').off('click.s_ec').on('click.s_ec', function() {
      if($(this).prop('checked')) {
        $('#s_cms').prop('checked', false)
        $('#s_itzi').prop('checked', false)
        $(this).prop('checked', true)
      }
    })

    $('#s_cms').off('click.s_cms').on('click.s_cms', function() {
      if($(this).prop('checked')) {
        $('#s_ec').prop('checked', false)
        $('#s_itzi').prop('checked', false)
        $(this).prop('checked', true)
      }
    })

    $('#s_itzi').off('click.s_itzi').on('click.s_itzi', function() {
      if ($(this).prop('checked')) {
        $('#s_ec').prop('checked', false)
        $('#s_cms').prop('checked', false)
        $(this).prop('checked', true)
      }
    })

    // [同行有無]
    $('[name="FollowNeed"]').off('click.FollowNeed').on('click.FollowNeed', function() {
      that.inputFollowNeed = func.check($('[name="FollowNeed"]'))

      if(that.inputFollowNeed == 'あり'){
        $('#feild_FollowerName').slideDown(500)
      } else {
        $('#feild_FollowerName').slideUp(500)
        $('#searchFollowerName').val('')

        func.checkRemove($('[name="ListFollower"]'))
        
        $('.ListFollower, .msg_loading').remove()
      }
    })

    // [商談形式：表示非表示]
    $('#MtgType').off('change.MtgType').on('change.MtgType', function() {
      that.inputMtgType = $('#MtgType').val()

      if(that.inputMtgType == '来社') {
        $('#visit_area').slideDown(300)
        $('[name="VisitArea"]').addClass('required')
      } else {
        $('#visit_area').slideUp(300)
        func.checkOut($('[name="VisitArea"]'))
        $('[name="VisitArea"]').removeClass('required')
      }

      if(that.inputMtgType == 'カフェ' || that.inputMtgType == '訪問') {
        $('.field_taimen').slideDown(300)
        $('#MtgAdd, #Station, #StationMin').addClass('required')
      } else {
        $('.field_taimen').slideUp(300)
        $('#MtgAdd, #Station, #StationMin').val('')
        $('#MtgAdd, #Station, #StationMin').removeClass('required')
      }
    })


    // カレンダー
    $('#MtgDate').off('click.MtgDate').on('click.MtgDate', function() {
      let _this = $(this)
      let date = _this.val()
      let current = date && date.match(/[0-9]{4}\/[0-9]{2}\/[0-9]{2}/)? date: moment().toDate()
/*
      _this.datepicker("dialog", current, function(date) {
        _this.val(date)
      }, {
        dateFormat : 'yy-mm-dd',
        monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
      })*/
    })

    // [timepicker]
    $('#MtgTime').off('change.MtgTime').on('change.MtgTime', function() {
      let fromT = $('#MtgStart').val()
      let toT = $('#MtgEnd').val()
      const reg = /\d{2}:\d{2}/

      if (fromT.indexOf(':') === -1) {
        fromT = ""
        $('#MtgStart').val(fromT)
      }

      if (toT.indexOf(':') === -1) {
        toT = ""
        $('#MtgEnd').val(toT)
      }

      if(reg.test(fromT) && reg.test(toT)){
        const result = func.duration(fromT, toT)
        if(result == 0) $('#MtgStart, #MtgEnd').val('')
      }
    })

    // [徒歩：入力制限]
    $('#StationMin').off('input.StationMin').on('input.StationMin',function(){
      let StationMin_val = String($(this).val())
      $(this).val(func.hankakusuji(StationMin_val))
    })

    // [必須項目設定]
    $(document).off('change.setRequired input.setRequired click.setRequired mousemove.setRequired').on('change.setRequired input.setRequired click.setRequired mousemove.setRequired', '#form_ap', function() {
      $('#btn').addClass('disabled').removeClass('pulse')

      let requiredArray = {}
      let required_dom = $('.required')
      let key_str = ""

      let bg_color = '#ffcdd2'
      let color = '#212121'
      
      $('.required').css({
        'background-color': bg_color,
        'color': color
      })

      // [形式チェック：商談住所]
      let format_flags = ""
      let trigger = $('[name="SubjectId"]')
      let label_tag = '<label class="error_msg_pref">※ 半角英数字で入力してください。</label>'
      let insertbfr = $('[name="SubjectId"]')[0]
      format_flags += func.format_check_subjectId(trigger, label_tag, insertbfr, format_flags)
      
      if($('#MtgAdd').hasClass('required')) {
        let trigger = $('[name="pref_name"]')
        let label_class = '.error_msg_pref'
        let label_tag = '<label class="error_msg_pref">※ 都道府県名から入力してください</label>'
        let insertbfr = $('[name="pref_name"]')
        format_flags += func.format_check_pref(trigger, label_class, label_tag, insertbfr, format_flags)
      }

      for(let num = 0; num < required_dom.length; num++) {

        if(required_dom[num].type == 'radio' || required_dom[num].type == 'checkbox') {
          key_str = required_dom[num].name
          requiredArray[key_str] = 0
          
          let name_dom = $('[name=' + key_str + ']')
          name_dom.next().css({
            'background-color': bg_color,
            'color': color
          })

          if(func.check(name_dom) !== '') {
            requiredArray[key_str] = 1
            name_dom.next().css({'background-color': '#ffffff'})
          }
        } else if(required_dom[num].classList.contains('chip')) {
          key_str = "EvaluationReason"
          requiredArray[key_str] = 0

          let name_dom = $('[name=' + key_str + ']')
          name_dom.css({
            'background-color': bg_color,
            'color': color
          })

          if(func.checked_chip($('div.teal.lighten-2')) != '') {
            requiredArray[key_str] = 1
            name_dom.css({'background-color': '#e0e0e0'})
          }
        } else {
          key_str = required_dom[num].id
          requiredArray[key_str] = 0

          if(required_dom[num].value != '') {
            requiredArray[key_str] = 1
            required_dom[num].style.backgroundColor = '#ffffff'
          }
        }
      }

      let required_NG = 0
      let check_required = 0

      for(let key in requiredArray) {
        if(requiredArray[key] == 0) {
          required_NG = 1
        }else{
          check_required = 1
        }
      }

      if(check_required == 1 && required_NG == 0 && format_flags.indexOf('0') == -1) {
        $('#btn').removeClass('disabled').addClass('pulse')
      }
    })

    // [案件ID検索結果]
    $(document).off('click.ListEvents').on('click.ListEvents', '[name="ListEvents"]', function() {
      
      let dataAbout = $(this).data('about')
      let dataRes = $(this).data('res')

      that.inputComName = dataAbout.company_name
      that.inputMtgGDate = dataAbout.mtg_date
      that.inputCalName = dataAbout.cal_name
      that.inputApTypeName = dataAbout.ap_type_name.replace('🎦', 'オンライン')
      that.resCalId = dataRes.cal_id
      that.resEventId = dataRes.event_id
      that.resMtgType = dataRes.mtg_type
      that.resMtgAdd = dataRes.mtg_add
      that.resHook = dataRes.hook
      that.resSuggestion = dataRes.suggestion
      that.resApType = dataRes.ap_type
      that.resVisitArea = dataRes.visit_area
      that.resConfirmAp = dataRes.confirm_ap

      $('#ComName').val(that.inputComName)

      const isIncludeSaihouCal = officeData.checkIncludeSaihouCal(that.resCalId)
      const isIncludeSelfSalesCal = officeData.checkIncludeSelfSalesCal(that.resCalId)
      const isRescanCal = officeData.checkRescanCal(that.resCalId)

      if(isIncludeSaihouCal || (isRescanCal && that.resApType == '再訪')) { //再訪案件
        that.inputSaihou = '(再訪)'
        $('.filed_proxy').css('display', 'none')
      } else if(isIncludeSelfSalesCal) { //自アポ
        $('.filed_proxy').css('display', 'none')
        that.inputSaihou = ''
      } else {
        that.inputSaihou = ''
      }

      $('#wrap2,.autoG').slideDown()

      if(
        that.inputCalName.indexOf('リスキャン') !== -1 ||
        (
          that.inputCalName.indexOf('リスキャン') == -1 &&
          that.inputMtgGDate.split('）')[1].split('〜')[0] == '00:00'
        ) ||
        that.resConfirmAp == '＊'
      ) {
        $('select#Status option[value=キャンセル],select#Status option[value=リスケ],select#Status option[value=前不],select#Status option[value=前不→復活]').remove()
      } else {
        $('select#Status option[value=復活]').remove()
      }

      if(that.resConfirmAp == '-') {
        $('select#Status option[value=前不],select#Status option[value=前不→復活]').remove()
      } else if(that.resConfirmAp == '☆') {
        $('select#Status option[value=前不],select#Status option[value=前不→復活]').remove()
      } else if(that.resConfirmAp == '★') {
        $('select#Status option[value=前不→復活]').remove()
      } else {
        $('select#Status option[value=前不→復活]').remove()
      }

      $('select#MtgType option[data-type=' + that.resMtgType + ']').remove()
    })

    // [変更点：表示非表示]
    $(document).off('click.checkChangedPoints').on('click.checkChangedPoints', '.checkChangedPoints', function() {
      const Subject_Id = $('#SubjectId').val()
      let mtgStartTime_now = '',
      mtgEndTime_now = '',
      mtgDate_now = ""

      $('.filed_MtgDate,.filed_MtgAdd,.filed_MtgType,.filed_duration').css('display', 'none')

      if(Subject_Id.startsWith('sungrove') == false) {
        let format = 'YYYY-MM-DD HH:mm'
        mtgStartTime_now = $('#MtgDate').val().split('）')[1].split('〜')[0]
        mtgEndTime_now = $('#MtgDate').val().split('）')[1].split('〜')[1]
        mtgDate_now = $('#MtgDate').val().split('（')[0].replace(/\//g, '-')
      }

      if($('#checkMtgDate').is(':checked')) {//商談日時
        $('.filed_MtgDate').css('display', 'block')
        $('#ChangeDate, #ChangeStart, #ChangeEnd').addClass('required')
        $('#ChangeStart, #ChangeEnd').prop('readonly', true)

        if( ! $('#checkDuration').is(':checked')) {
          $('#ChangeStart').val(mtgStartTime_now)
          $('#ChangeEnd').val(mtgEndTime_now)
        }
      } else {
        $('#ChangeDate, #ChangeStart, #ChangeEnd').val('')
        $('#ChangeDate, #ChangeStart, #ChangeEnd').removeClass('required')
        $('#ChangeStart, #ChangeEnd').prop('readonly', false)
      }

      if($('#checkDuration').is(':checked')) {//商談時間
        $('.filed_duration, .filed_MtgDate').css('display', 'block')
        $('#Duration').addClass('required')
        $('#ChangeDate').prop('readonly', true)

        if( !$('#checkMtgDate').is(':checked')) {
          $('#ChangeDate').val(mtgDate_now)
        }
      } else {
        $('#Duration').val('')
        $('#Duration').removeClass('required')
        $('#ChangeDate').prop('readonly', false)
      }

      if($('#checkMtgDate:checked, #checkDuration:checked').length == 2) {
        $('#ChangeDate, #ChangeStart, #ChangeEnd').prop('readonly',false)
      }

      if($('#checkMtgType').is(':checked')) {
        $('#MtgType').addClass('required')
        $('.filed_MtgType').css('display', 'block')
      } else {
        let apTypeVal = $('#ApType').val()

        $('#MtgType').val('')
        $('#MtgType,#Device,[name="area"]').removeClass('required')
        $('.filed_Device,.filed_VisitArea').css('display', 'none')

        func.clearSelect($('#Device'))
        func.checkOut($('[name="area"]'))

        if(apTypeVal.indexOf('対面') !== -1 && $(this).val() == '商談形式') { //商談形式を選択=>商談形式外す=>商談住所チェック可能
          $('#checkMtgAdd').prop({'disabled': false, 'checked': false})
        } else if(apTypeVal.indexOf('オンライン') !== -1 && $(this).val() == '商談形式') {//商談形式を選択=>商談形式外す=>商談住所が非活性
          $('#checkMtgAdd').prop({'disabled': true, 'checked': false})
        }
      }

      if($('#checkMtgAdd').is(':checked')) {
        $('#MtgAdd').addClass('required')
        $('#MtgAdd').prop('required', true)
        $('.filed_MtgAdd').css('display', 'block')
      } else {
        $('#MtgAdd').val('')
        $('#MtgAdd').prop('required', false)
        $('#MtgAdd').removeClass('required')
      }
    })

    // [変更点：未定同時選択不可]
    $('#checkYet').off('click.checkYet').on('click.checkYet', function () {
      if($(this).prop('checked')) {
        $('#checkMtgDate, #checkDuration, #checkMtgAdd, #checkMtgType').prop('checked', false)
        $(this).prop('checked', true)
      }
    })

    $('#checkMtgDate, #checkDuration, #checkMtgAdd, #checkMtgType').off('click.checkYet').on('click.checkYet', function () {
      if($(this).prop('checked')) {
        $('#checkYet').prop('checked', false)
        $(this).prop('checked', true)
      }
    })

    // [変更点：復活なし、同時選択不可]
    $('#checkNo').off('click.checkNo').on('click.checkNo', function () {
      if($(this).prop('checked')) {
        $('#checkMtgDate,#checkDuration,#checkMtgType,#checkMtgAdd').prop('checked', false)
        $(this).prop('checked', true)
      }
    })

    $('#checkMtgDate, #checkDuration, #checkMtgType, #checkMtgAdd').off('click.checkNo2').on('click.checkNo2', function () {
      if($(this).prop('checked')) {
        $('#checkNo').prop('checked', false)
        $(this).prop('checked', true)
      }
    })

    // [ネック：表示非表示]
    $(document).off('click.neck').on('click.neck', '.neck_li', function() {
      let id_name = $(this).attr('id')
      
      if($('#neck_' + id_name).hasClass("up")) { //非表示→表示
        $(this).parent('div').addClass('grey')
        $('#neck_' + id_name).show(400)
        $('#neck_' + id_name).removeClass("up")
        $('#neck_' + id_name).addClass("down")
      } else if($('#neck_' + id_name).hasClass("down")) { //表示→非表示
        $('#neck_' + id_name).hide(400)
        $(this).parent('div').removeClass('grey')
        $('#neck_' + id_name).removeClass("down")
        $('#neck_' + id_name).addClass("up")
      }

      // [必須項目設定]ループ処理で一つでもdownクラスがあるかどうか
      if(func.checked_neckCate() == 1) {
        $('[name="ResuCanReasonCate"]').addClass('required')
        func.checkOut($('[name="neckEle"]'))
      }
    })

    // [ネック：チェック動作]
    $(document).off('click.neck2').on('click.neck2', '[name="neckEle"]', function() {
    
      const CheckedNeck = $(this)
      const this_class_name = CheckedNeck.attr("class").replace('required','')
      let neck_flag = ""
      
      $('.' + this_class_name).each(function(i, elem) {
        if($(elem).prop('checked')) {
          neck_flag += "1"
        } else {
          neck_flag += "0"
        }
      })

      if(neck_flag.indexOf('1') != -1) { // 中カテで1つでもチェックされている
        CheckedNeck.parent().parent().parent().removeClass("down")

        let id = CheckedNeck.parent().parent().parent().attr('id')
        let splited_id = id.split('_')[1]

        $('#' + splited_id).parent().removeClass('grey').addClass(that.checked_color)
      } else { // チェック外した
        CheckedNeck.parent().parent().parent().addClass("down")

        let id = CheckedNeck.parent().parent().parent().attr('id')
        let splited_id = id.split('_')[1]

        $('#' + splited_id).parent().removeClass(that.checked_color).addClass('grey')
      }

      const neckName = this_class_name.split('_')[1]
      if(CheckedNeck.is(':checked') && CheckedNeck.val() == 'その他') { //中カテその他がチェックされた場合
        $('#sonota_' + neckName).slideDown(400).addClass('required')
      } else if( ! CheckedNeck.is(':checked') && CheckedNeck.val() == 'その他') {
        $('#sonota_' + neckName).slideUp(400).val('').removeClass('required')
      }
    })

    // [必須項目設定]
    $(document).off('change.required input.required click.required mousemove.required').on('change.required input.required click.required mousemove.required', '#form_ap', function() {
      let requiredArray = {}
      let required_dom = $('.required')
      let key = ""
      let bg_color = '#ffcdd2'
      let color = '#212121'

      $('#btn').addClass('disabled').removeClass('pulse')
      $('.required').css({
        'background-color': bg_color,
        'color': color
      })
      
      // [形式チェック：商談住所]
      let format_flag = ""
      let format_flags = ""

      if($('#MtgAdd').prop('required')) {
        let trigger = $('[name="pref_name"]')
        let label_class = '.error_msg_pref'
        let label_tag = '<label class="error_msg_pref">※ 都道府県名から入力してください</label>'
        let insertbfr = $('[name="pref_name"]')

        format_flags = func.format_check_pref(trigger, label_class, label_tag, insertbfr)
      }

      for(let num = 0; num < required_dom.length; num++) {
        if(required_dom[num].type == 'radio' || required_dom[num].type == 'checkbox') {
          key = required_dom[num].name
          requiredArray[key] = 0
          let name_dom = $('[name=' + key + ']')

          name_dom.next().css({
            'background-color': bg_color,
            'color': color
          })

          if(func.check(name_dom) != '') {
            requiredArray[key] = 1
            name_dom.next().css({'background-color': '#ffffff'})
          }

        } else if(required_dom[num].classList.contains('chip')) {
          key = "ResuCanReasonCate"
          requiredArray[key] = 0
          let name_dom = $('[name=' + key + ']')

          name_dom.css({
            'background-color': bg_color,
            'color': color
          })

          if(func.checked_chip($('div.teal.lighten-2')) != '') {
            requiredArray[key] = 1
            name_dom.css({'background-color': '#e0e0e0'})
          }

        } else {
          key = required_dom[num].id
          requiredArray[key] = 0

          if(required_dom[num].value !='') {
            requiredArray[key] = 1
            required_dom[num].style.backgroundColor = '#ffffff'
          }

        }
      }

      let required_NG = 0
      let check_required = 0

      for(let key in requiredArray) {
        if(requiredArray[key] == 0) {
          required_NG = 1
        } else {
          check_required = 1
        }
      }

      if(check_required == 1 && required_NG == 0 && format_flags.indexOf('0') == -1) {
        $('#btn').removeClass('disabled').addClass('pulse')
      }
    })

    //[商談時刻制限(遡り防止)]
    $('#MtgDate').change(function() {
      let formated_today = moment().format('YYYY-MM-DD')
      $('#MtgDateCheck').text("")
      
      if ($('#MtgDate').val() < formated_today) {
        $('#MtgDateCheck').text('※ 本日以降の日付を入力してください').css('color', '#e91e63')
        $('#MtgDate').val("")
      }
      
    })

    //API 同行者詳細検索
    let request_response = ""
    $('#searchFollowerName').off('input.searchFollower').on('input.searchFollower', function(e) {

      if(that.intervalObj) {
        clearInterval(that.intervalObj)
        if(that.abortController && typeof that.abortController.abort == 'function') that.abortController.abort()
        that.abortController = new AbortController()
      }

      let _this = $(this)
      let search_FollowerName = _this.val()
      $('.ListFollower, .msg_loading').remove()
      $('<label class="pd5_rl msg_loading">検索中(_ _).｡o○</label>').appendTo("#feild_FollowerName")

      if(search_FollowerName == "") {
        $('.ListFollower, .msg_loading').remove()
        return false
      }

      that.intervalObj = setTimeout(() => {
        that.abortController = new AbortController()
        /*const params = {
          type: 'getFollowersData',
          searchName: search_FollowerName
        }*/

        const params = {
          authC: {
            password: "W!z|a~.x.T~Tzca8CBCm",
            mail: that.userEmail
          },
          main_data: {
            tables:["users"],
            query: `SELECT * FROM users WHERE (first_name LIKE "%${search_FollowerName}%" OR business_name LIKE "%${search_FollowerName}%") AND depts_id IN (1, 10, 26)`
          },
          support_data: {
            is_prod: that.isProd
          }
        }

        const color = '#e91e63'
        //const query_params = new URLSearchParams(params)
        //const searchApiURL = `${that.getDataApiURL}?${query_params}`
        const searchApiURL = that.dbApiURL + 'db/select'

        // Loading表示
        that.vueObj.viewLoader('同行者検索中');

        fetch(searchApiURL, {
          signal: that.abortController.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Cal-St-Api': that.key
          },
          body: JSON.stringify(params)
        })
        .then(response => response.json())
        .then(response => {
          
          const mes = $('.msg_loading')
          let text = ''
          const res = typeof response == 'String'? response - 0 : response 
          request_response = response

          switch(res) {
            case 0: {
              if(response[0].user_id !== '#N/A') {
                mes.remove()
                func.createFollowerList(response)
              } else {
                text = '検索できませんでした。検索ワードをもう一度確認してから検索してください。'
              }
              break
            }
            case 1: {
              text = 'アクセスが許可されていません。'
              break
            }
            case 2: {
              text = 'リクエストデータがありません。'
              break
            }
            case 3: {
              text = '認証が通りませんでした。'
              break
            }
            case 4: {
              text = '検索結果が50件を越えています。検索結果を絞るために文字を追加してください。'
              break
            }
            case 99: {
              text = '何らかのエラーが発生しました。'
              break
            }
          }

          if(text) {
            mes.text(text).css('color', color)
            alert(text)
          }

          if(response[0].user_id !== '#N/A') {
            mes.remove()
            func.createFollowerList(response)
          }

          // Loading非表示
          that.vueObj.viewLoader();

        })
        .catch(error => {
          console.log({error})
          // Loading非表示
          that.vueObj.viewLoader();
          if(error.name != "AbortError") {
            $('.msg_loading').text('エラー').css('color', color)
          }
        })
      }, 700)

    })

    //送信用メッセ
    document.getElementById("btn").onclick = function exchange() {
      if($('#btn').hasClass('pulse')) {
        let WeekChars = [ "日", "月", "火", "水", "木", "金", "土" ]

        // [値取得]
        that.inputSubjectId = $('#SubjectId').val()
        that.inputComName = $('#ComName').val()
        that.inputMtgDate = $('#MtgDate').val()
        that.inputMtgDay = "(" + WeekChars[new Date(that.inputMtgDate).getDay()] + ")"
        that.inputMtgStart = $('#MtgStart').val()
        that.inputMtgEnd = $('#MtgEnd').val()
        that.inputRevisitType = func.check ($('[name="RevisitType"]'))
        that.inputHook = func.check($('[name="Hook"]'))
        that.inputSuggestion = func.check($('[name="Suggestion"]'))
        that.inputMtgType = $('#MtgType').val()
        that.inputMtgAdd = $('#MtgAdd').val()
        that.inputStation = $('#Station').val()
        that.inputFollowNeed = func.check($('[name="FollowNeed"]'))
        that.inputFollowerName = func.check($('[name="ListFollower"]'))
        that.inputRemark = $('#Remark').val()
        that.inputVisitArea = func.check($('[name="VisitArea"]'))

        // 【フック+提案内容+商談形式+再訪種別】アポ種別
        that.ApType_mtgType = $('.MtgType:selected').data('type')
        that.APType_hook = $('[name="Hook"]:checked').data('type')
        that.APType_suggestion = that.inputSuggestion.split('/')[0]

        let ApType_area =""
        if(that.inputMtgType == '来社' && that.inputVisitArea !== ""){
          that.inputVisitArea = $('[name="VisitArea"]:checked').data('type') + "来社\n"
          ApType_area = $('[name="VisitArea"]:checked').data('type')
        } else if(that.ApType_mtgType == '対面' && (that.inputMtgAdd.startsWith("大阪府") == true || that.inputMtgAdd.startsWith("滋賀") == true || that.inputMtgAdd.startsWith("兵庫") == true || that.inputMtgAdd.startsWith("和歌山") == true || that.inputMtgAdd.startsWith("京都府") == true || that.inputMtgAdd.startsWith("奈良") == true)) {
          ApType_area = "関西"
        } else if(that.ApType_mtgType == '対面' && (that.inputMtgAdd.startsWith("東京都")  == true || that.inputMtgAdd.startsWith("埼玉") == true || that.inputMtgAdd.startsWith("つくば") == true || that.inputMtgAdd.startsWith("千葉") == true || that.inputMtgAdd.startsWith("茨城") == true || that.inputMtgAdd.startsWith("神奈川") == true)){
          ApType_area = "関東"
        }

        if(that.inputHook == that.inputSuggestion) {
          that.inputApType = that.inputSuggestion + ApType_area + that.ApType_mtgType + that.inputRevisitType
        } else {
          that.inputApType = that.APType_hook + that.APType_suggestion + ApType_area + that.ApType_mtgType + that.inputRevisitType
        }

        if(that.inputStation !== '') {
          that.inputStationMin = "駅　徒歩" + $('#StationMin').val() + "分" 
        }

        if(that.inputStation.slice(-1) == '駅') {
          that.inputStation = that.inputStation.slice(0,-1)
          alert(that.inputStation)
        }

        let gas_mtg_date = that.inputMtgDate
        let gas_mtg_start = that.inputMtgDate + " " + that.inputMtgStart
        let gas_mtg_end = that.inputMtgDate + " " + that.inputMtgEnd
        that.inputMtgDate = that.inputMtgDate.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1年$2月$3日')
        that.inputMtgStart = that.inputMtgStart.replace(/(\d{2}):(\d{2})/,'$1時$2分')
        that.inputMtgEnd = that.inputMtgEnd.replace(/(\d{2}):(\d{2})/, '$1時$2分')

        //[拡張機能バージョン]
        let Extension_version = that.store.state.version

         // talknote投稿
        ;(async function() {
      
          let tn_user_id = that.store.state.userData.userID

          let postID = func.getPostID(that.inputSubjectId, that.slackChannel.userID, that.slackChannel.id)

          //[投稿画面バージョン]
          let toSpreadsheet = "案件ID：" + that.inputSubjectId +
                              "\r\n【" + that.inputApType + "】" + "[" + Extension_version + "]" +
                              "\r\n案件名：" + that.inputComName +
                              "\r\n商談日時：" + that.inputMtgDate + that.inputMtgDay + that.inputMtgStart + "〜" + that.inputMtgEnd +
                              "\r\n再訪種別：" + that.inputRevisitType +
                              "\r\n" +
                              "\r\nフック：" + that.inputHook +
                              "\r\n提案内容：" + that.inputSuggestion +
                              "\r\n商談形式：" + that.inputMtgType +
                              "\r\n商談住所：" + that.inputMtgAdd +
                              "\r\n最寄り駅：" + that.inputStation + that.inputStationMin +
                              "\r\n同行有無：" + that.inputFollowNeed +
                              "\r\n同行者名：" + that.inputFollowerName +
                              "\r\n" +
                              "\r\n備考：" + that.inputVisitArea + that.inputRemark +
                              '\r\n' + '-----------------------------' +
                              '\r\n' + 'POST ID：' + postID +
                              '\r\n' + 'こちらのIDは投稿画面からの投稿IDです。'

          // slack 投稿者にメンション
          const slackMention = `<@${that.slackChannel.userID}>\n`

          let toSlack =  slackMention + "案件ID：" + that.inputSubjectId +
                            "\r\n【" + that.inputApType + "】" + "*[" + Extension_version + "]" +
                            "\r\n商談日時：" + that.inputMtgDate + that.inputMtgDay + that.inputMtgStart + "〜" + that.inputMtgEnd +
                            "\r\n再訪種別：" + that.inputRevisitType +
                            "\r\n" +
                            "\r\nフック：" + that.inputHook +
                            "\r\n提案内容：" + that.inputSuggestion +
                            "\r\n商談形式：" + that.inputMtgType +
                            "\r\n" +
                            "\r\n同行有無：" + that.inputFollowNeed +
                            "\r\n同行者名：" + that.inputFollowerName +
                            "\r\n" +
                            "\r\n備考：" + that.inputVisitArea + that.inputRemark +
                            '\r\n' + '-----------------------------' +
                            '\r\n' + 'POST ID：' + postID +
                            '\r\n' + 'こちらのIDは投稿画面からの投稿IDです。'

          const requestFunc = new RequestBody.requestBody()
          const groupName = "saihou"
          const spreadsheetApi = that.store.state.apis[`spreadsheet${that.demo}`]
          const calCreateApi = that.store.state.apis[`calCreate${that.demo}`] + "/revisit"
          const NowTime = requestFunc.nowTime()
          const created_at = requestFunc.nowTime("created_at")

          const userData = that.store.state.userData
          const belongData = requestFunc.setBelongDate(userData)

          if (userData.BN !== "" && userData.BN !== null && userData.BN !== "NULL" && userData.BN !== "None") {
            var fullname = userData.BN + "（" + userData.firstName + "） " + userData.lastName
          } else {
            var fullname = userData.firstName + " " + userData.lastName
          }
       

          /*********************************************************
           * 投稿開始
           * *******************************************************/
          // Loading表示
          that.vueObj.viewLoader('投稿中')

          // slack投稿
          const data = {
            "channel": that.slackChannel.id, 
            "username": fullname,
            "text":  toSlack, 
            "token": that.slackChannel.token,
            "icon_url": that.slackChannel.iconURL
          }

          const response = await fetch(that.slackChannel.postURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `token=${encodeURIComponent(data.token)}&channel=${encodeURIComponent(data.channel)}&username=${encodeURIComponent(data.username)}&text=${encodeURIComponent(data.text)}&icon_url=${data.icon_url}`
          })
          .then(function (response) {
            return response.json()
          }).then(function (response) {
    
            if(response.message == "not current version") {
              alert(`tnPostのバージョンが違うようです。\n更新します。`);
              location.reload()
              postResult = false
              return false
            } else {
              
              console.log('slack投稿完了', response)
    
              return response
            }
    
          })
          .catch((e) => {
            console.error(e.stack, e.message)
          })         

          const messageURL = `${that.groupURL}/p${response.ts.split('.').join('')}`


          // 転送チャンネルに投稿
          const postParam = {
            slackChannel: that.slackChannel,
            messageURL: messageURL,
            postUser: fullname,
            mention: slackMention,
            text: toSpreadsheet,
            channelID: that.slackChannel.forwardID01
          }
          new ForwardPost(postParam).post()


          const insertDataRecordCnt = [{
                                      "作成日時":{"id": "", "val": NowTime},
                                      "全文": {"id": "", "val": func.textEscape(toSpreadsheet)},
                                      "投稿URL":{"id": "", "val": messageURL},
                                      "ユーザーID":{"id": "", "val": tn_user_id},
                                      "投稿者名":{"id": "", "val": fullname},
                                      "所属エリア":{"id": "", "val": belongData.userPrefecture},
                                      "所属（課/部）":{"id": "", "val": belongData.userSection},
                                      "案件ID":{"id": "", "val": that.inputSubjectId},
                                      "投稿ID":{"id": "", "val": postID},
                                      "案件名":{"id": "", "val": func.textEscape(that.inputComName)},
                                      "商談日":{"id": "", "val": gas_mtg_date},
                                      "開始時間":{"id": "", "val": that.inputMtgStart},
                                      "終了時間":{"id": "", "val": that.inputMtgEnd},
                                      "再訪種別":{"id": "", "val": that.inputRevisitType},
                                      "フック":{"id": "", "val": that.inputHook},
                                      "提案内容":{"id": "", "val": that.inputSuggestion},
                                      "商談形式":{"id": "", "val": $('.MtgType:selected').data('type')},
                                      "商談住所":{"id": "", "val": func.textEscape(that.inputVisitArea)},
                                      "同行者有無":{"id": "", "val": func.textEscape(that.inputFollowNeed)},
                                      "同行者名":{"id": "", "val": func.textEscape(that.inputFollowerName)},
                                      }]
  
          let sheetData = requestFunc.createBody(groupName, that.isProd, insertDataRecordCnt)

          let calLinkSheetData = JSON.parse(JSON.stringify(sheetData))
          const today = NowTime.split(" ")[0].replace("/", "-").replace("/", "-")
          const isToday = today == gas_mtg_date
          const spread = requestFunc.spreadDesignationCalLink(isToday, that.isProd)
          calLinkSheetData.spread.id = spread.spreadsheetID
          calLinkSheetData.spread.sheet_name = spread.sheetName

          tn_user_id = tn_user_id.toString()
          let hook_id = parseInt($('[name="Hook"][value="' + that.inputHook + '"]').attr('data-id'))
          hook_id = hook_id || ""
          let area = ""
          let add = that.inputMtgAdd
          const type_id = parseInt($('.MtgType[data-type="' + $('.MtgType:selected').data('type') + '"]').attr('data-id'))

          if (type_id == 2) {
            area = func.textEscape(that.inputVisitArea).replace("来社", "").replace(/\n/g, "")
            add = ""
          }
          
          let revisit_type = []
          if (that.inputRevisitType.includes('/')) {
            const splittedStrings = that.inputRevisitType.split("/")

            for (let i = 0; i < splittedStrings.length; i++) {
              revisit_type.push(parseInt($('[name="RevisitType"][value="' + splittedStrings[i] + '"]').attr('data-id')))
            }

          } else {
            revisit_type.push(parseInt($('[name="RevisitType"][value="' + that.inputRevisitType + '"]').attr('data-id')))
          }

          const started_at = that.inputMtgStart.replace('時', ':').replace('分', '')
          const ended_at = that.inputMtgEnd.replace('時', ':').replace('分', '')

          let suggestionId = parseInt($('[name="suggestion"][value="' +  that.inputSuggestion + '"]').attr('data-id'))
          if (isNaN(suggestionId)) {
            suggestionId = 99
          }

          let follower_user_id = ""

          if (request_response != "") {
            const checked_follower = $('[name="ListFollower"][value="' + func.textEscape(that.inputFollowerName) + '"]')
            let sg_id = ""
  
            if (checked_follower.length > 0) {
              const data_user = checked_follower.attr('data-user')
              const user_data = JSON.parse(data_user)
              sg_id = user_data.user_id
            }

            for (let i = 0; i < request_response.length; i++) {
              if (request_response[i].id == sg_id) {
                follower_user_id = request_response[i].id.toString()
                break
              }
            }

          }
          
          let calData = {
            "sg_data": {
                        "is_prod": that.isProd,
                        "message": func.textEscape(toSpreadsheet),
                        "created_at": created_at,
                        "group_id": that.slackChannel.id,
                        "user_id": userData.id
                        },
            "subject": {
                        "id": that.inputSubjectId,
                        "name": func.textEscape(that.inputComName),
                        "hook_id": hook_id,
                        "suggestion_id": suggestionId
                        },
            "nego": {
                    "revisit_type": revisit_type,
                    "date": gas_mtg_date,
                    "started_at": started_at,
                    "ended_at": ended_at,
                    "type_id":  type_id,
                    "area": area,
                    "add": add,
                    "follower_user_id": follower_user_id
                    }
          }

          $('#btn').addClass('disabled').removeClass('pulse')
          
          const spreadRequest = requestFunc.apiRequestExecution(spreadsheetApi, sheetData, that.key)
          const calRequest = requestFunc.apiRequestExecution(calCreateApi, calData, that.key)
          Promise.all([spreadRequest, calRequest])
          .then(async function (response) {
            const spreadResponse = response[0]
            const calResponse = response[1]
            console.log('シート登録完了', spreadResponse)
            console.log('カレンダー登録完了', calResponse)
            const responseData = await calResponse.json()
            const location = responseData.hasOwnProperty("location") ? responseData.location : ""
            const colorId = responseData.hasOwnProperty("colorId") ? responseData.colorId : "0"
            const colorName = requestFunc.calColorNameList(colorId)
            const insertDataCalLink = [{
              "created_at":{"id": "", "val": NowTime},
              "案件ID":{"id": "", "val": that.inputSubjectId},
              "投稿ID":{"id": "", "val": postID},
              "イベントID":{"id": "", "val": responseData.iCalUID},
              "開始時間":{"id": "", "val": gas_mtg_date + " " + that.inputMtgStart},
              "終了時間":{"id": "", "val": gas_mtg_date + " " + that.inputMtgEnd},
              "タイトル":{"id": "", "val": responseData.summary},
              "説明":{"id": "", "val": func.textEscape(toSpreadsheet)},
              "カレンダーID":{"id": "", "val": responseData.organizer.email},
              "商談住所":{"id": "", "val": location},
              "ゲスト":{"id": "", "val": responseData.attendees ? responseData.attendees[0].email : ""},
              "色":{"id": "", "val": colorName},
              "投稿URL":{"id": "", "val": messageURL},
              "イベントURL":{"id": "", "val": responseData.htmlLink},
            }]
            calLinkSheetData.insert_data = insertDataCalLink
            calLinkSheetData.spread.reference_row = 2
            const calLintkSpreadRequest = requestFunc.apiRequestExecution(spreadsheetApi, calLinkSheetData, that.key)
            return {responseData, calLintkSpreadRequest}
          })
          .then(async function ({responseData, calLintkSpreadRequest}) {

            await calLintkSpreadRequest
            // 完了画面表示
            that.vueObj.viewLoader()

            const params = {
              groupTitle: that.groupTitle,
              groupURL: that.groupURL,
              searchURL: messageURL,
              calURL: responseData.htmlLink,
              message: '',
              completeTitle: '投稿完了'
            }
            that.vueObj.viewComplete(params)
          })

          .catch((e) => {
            console.error(e.stack, e.message)
            alert('カレンダー登録中にタイムアウトしました。\n別途再投稿指示があった場合は、再度投稿お願いします')
            let message = ""
            if (e.name === 'AbortError') {
              message = requestFunc.createCalTimeOutMessage(messageURL)
            } else {
              message = requestFunc.createCalFailureApplyMessage(messageURL)
            }


            const data = {
              "channel": that.calendarFailureApplySlackChannelID, 
              "username": fullname,
              "text":  "【" + that.groupTitle + "】" + message, 
              "token": that.slackChannel.token,
              "icon_url": that.slackChannel.iconURL
            }
   
            // slack投稿
            fetch(that.slackChannel.postURL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: `token=${encodeURIComponent(data.token)}&channel=${encodeURIComponent(data.channel)}&username=${encodeURIComponent(data.username)}&text=${encodeURIComponent(data.text)}&icon_url=${data.icon_url}`
            })
            window.location.href = '/'
          })
          
        })()

      }
    }
  }
}

import RequestBody from "../../assets/js/requestBody.class" 
import ForwardPost from "../../assets/js/forwardPost.class"
import OfficeDatas  from "./officeData"


export default {
  saihou
}